<template>
  <div>
    <tag-list/>
  </div>
</template>

<script>
import TagList from '@/components/TagList.vue';

export default {
  components: {
    TagList,
  },
};
</script>